import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout/MdxLayout.js";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
export const query = graphql`
  query {
    createGuideScreenshotImg: file(
      relativePath: { eq: "createGuideScreenshot.png" }
    ) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    checkWidgetImg: file(relativePath: { eq: "checkWidgetImg2.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    appEmbedImg: file(relativePath: { eq: "appEmbed.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    enableWidgetImg: file(relativePath: { eq: "enableWidget2-1.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Getting Started with Sizify`}</h1>
    <p><strong parentName="p">{`Install app`}</strong></p>
    <ul>
      <li parentName="ul">{`Go to the `}<em parentName="li">{`For Merchants`}</em>{` page then, click Start 14-day Free Trial`}</li>
      <li parentName="ul">{`Once app is installed, open it by navigating to Dashboard > Apps > Sizify`}</li>
    </ul>
    <p>{`Once Sizify is open, click `}<strong parentName="p">{`Create Size Guide.`}</strong></p>
    <Img className="mdxLayout-img" fixed={props.data.createGuideScreenshotImg.childImageSharp.fixed} mdxType="Img" />
    <p><strong parentName="p">{`Fill out step-by-step size guide wizard (~ in 2mins)`}</strong></p>
    <ol>
      <li parentName="ol">{`Size Guide Name (`}<em parentName="li">{`e.g Jeans 401)`}</em></li>
      <li parentName="ol">{`Select Product(s) - Choose the collection(s) or product(s) to include in size guide`}</li>
      <li parentName="ol">{`Size Variants `}<em parentName="li">{`(e.g Small, Medium, Large, etc)`}</em></li>
      <li parentName="ol">{`Parts of the Body `}<em parentName="li">{`(e.g for a pair of pants, select leg & hip)`}</em>{` - This depends on the measurement you have available to you & the style of clothing`}</li>
      <li parentName="ol">{`Size Guide Table - Input the clothing measurements in the units you prefer (cm or inches)`}</li>
    </ol>
    <p>{`Click `}<strong parentName="p">{`Save Size Guide.`}</strong></p>
    <p>{`To enable Sizify on your storefront go to, Dashboard (top of page) click `}<strong parentName="p">{`Proceed`}</strong>{`.`}</p>
    <Img className="mdxLayout-img" fixed={props.data.enableWidgetImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Then, ensure Sizify app is toggled to the "On" position`}</p>
    <Img className="mdxLayout-img" fixed={props.data.appEmbedImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Complete! To ensure success, wait a moment then navigate to your storefront. Check that the widget appears on the storefront, on the product page(s) that you assigned a size guide to earlier in tutorial.`}</p>
    <Img className="mdxLayout-img" fixed={props.data.checkWidgetImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`If widgets does not appear on your storefront theme check out `}<Link className="mdxLayout-link" to='/merchant-hc/troubleshooting/widget-not-appearing-on-website/' mdxType="Link">{` `}<strong parentName="p"><em parentName="strong">{`Troubleshooting`}</em></strong></Link></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      