import React from "react"

import NavStatic from '../../containers/navStatic/NavStatic'
import Footer from '../footer/Footer'

import "./MdxLayout.css"
import "../../sass/main.scss"

const MdxLayout = ({ children }) => {

  return (
    <div data-theme="light">
        <NavStatic/>
        <main className="mdxLayout-container">{children}</main>
       <Footer/>
    </div>
  )
}

export default MdxLayout
